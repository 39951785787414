import "./sitemap.scss"

import { Link, graphql } from "gatsby"
import * as React from "react"
import { Container } from "react-bootstrap"
import FindDreamJob from "../components/careers/finddreamjob"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"
const IndexPage = () => {
  const pageTitle = "Sitemap"
  const pageDescription = "BTS sitemap"
  const lang = "EN"
  const meta = []
  const breadcrumbMap = [{ title: "Sitemap", url: "#" }]
  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
        <div className="max-container-pages">
          <Seo
            title={pageTitle}
            description={pageDescription}
            lang={lang}
            meta={meta}
          />
          <Container fluid="xl">
            <section className="section-sitemap">
              <div className="sitemap-title-container">
                <div>
                  <span className="title">SITEMAP</span>
                </div>
                <br />
              </div>
              <div className="sitemap-subtitle-container mb-4">
                <span className="subtitle">
                  Navigate easily and discover all the information and services
                  that we offer
                </span>
              </div>
              <ul className="mt-4">
                <li className="section-header-title">
                  <Link to="/">Index</Link>
                </li>
                <li>
                  <Link className="section-header-title" to="/about/">
                    About BTS
                  </Link>
                  <ul>
                    <li className="bullet">
                      <Link to="/about/#ourculture">Our Culture</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="section-header-title" to="/careers/">
                    Careers
                  </Link>
                  <ul>
                    <li className="bullet">
                      <Link to="/careers/jobs/">Job Opportunities</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/">Join BTS</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/refer-a-friend/">Refer a Friend</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/internship/">Internship Program</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/mentoring/">Mentoring Program</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="section-header-title" to="/services/">
                    Services
                  </Link>
                  <ul>
                    <li className="bullet">
                      <Link to="/services/">Development Services</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/qaservices/">QA Services</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/education/">Technology Centers</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="section-header-title" to="/products/">
                    Products
                  </Link>
                </li>
                <li>
                  <Link
                    className="section-header-title"
                    to="/careers/locations/"
                  >
                    Locations
                  </Link>
                  <ul className="locations-list">
                    <li className="bullet">
                      <Link to="/careers/location-usa/">USA</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/location-mexico/">Mexico</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/location-argentina/">Argentina</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/location-spain/">Spain</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/location-uruguay/">Uruguay</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/location-bolivia/">Bolivia</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/location-peru/">Peru</Link>
                    </li>
                    <li className="bullet">
                      <Link to="/careers/location-tunisia/">Tunisia</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="section-header-title" to="/">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </section>
          </Container>
          <FindDreamJob></FindDreamJob>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
